import * as amplitude from "amplitude-js";

if (window.appConfig.amplitude.isEnabled) {
  const userProps = {
    is_webview: window.clientConfig.isWebview,
  };

  if (window.clientConfig.loadedUrlParams["ref"]) {
    userProps.ref = window.clientConfig.loadedUrlParams["ref"];
  }

  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties(userProps);
}

// --

export const userEvents = {
  // визит на главную страницу
  PAGE_INDEX: "page_index",

  // визит на страницу результата
  PAGE_RESULT: "page_result",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error, result)
  PHOTO_SELECT: "photo_select",

  TEMPLATE_SELECT: "template_select",
  TEMPLATE_SELECT_BACKGROUND: "template_select_background",
  TEMPLATE_LOADED: "template_loaded",
  TEMPLATE_DOWNLOAD: "template_download",
};

export const hits = {
  TAB_OPENED: 0,

  PHOTO_SELECT: 0,
  PHOTO_UPLOADED: 0,
  PHOTO_UPLOAD_FAILED: 0,

  PROCESSING_PROCESSED: 0,
  PROCESSING_FAILED: 0,

  SHARE: 0,
  DOWNLOAD: 0,
};

export function hitEvent(id) {
  if (id === 0) {
    return;
  }

  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    window.axios.post(`${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`)
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  logAmplitude(eventId, eventParams, cb);

  if (window.appConfig.analytics.isEnabled) {
    window.axios.post(window.appConfig.analytics.endpoint, {id: eventId, params: eventParams})
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logAmplitude(eventId, eventParams, cb) {
  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }
}