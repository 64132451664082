import React from "react";

export default function Loader({hidden, message}) {
  if (window.clientConfig.isWebviewIOS) {
    return <IosLoader hidden={hidden} message={message} />
  } else {
    return <AndroidLoader hidden={hidden} message={message} />
  }
}

function IosLoader({hidden, message}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-container">
    <div className="loader-ios">
      <span className="item-loader first-item-loader" />
      <span className="item-loader second-item-loader" />
      <span className="item-loader third-item-loader" />
    </div>
    <p dangerouslySetInnerHTML={{__html: message}} />
  </div>;
}

function AndroidLoader({hidden, message}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-container android">
    <div className="loader-circle-container">
      <p dangerouslySetInnerHTML={{__html: message}} />
      <div className="loader-circle">
        <span className="item-loader first-item-loader" />
        <span className="item-loader second-item-loader" />
        <span className="item-loader third-item-loader" />
      </div>
    </div>
  </div>;
}
