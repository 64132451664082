import React from "react";
import {webviewOpenBrowser} from "../utils/webview";

const PHOTO_LAB_LINK = "https://y3nay.app.goo.gl/creepyartist";

export class FooterLinksView extends React.Component {

  handlePhotoLabClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(PHOTO_LAB_LINK);
    }
  };

  render() {
    return <div className="footer-links">
      <a href={PHOTO_LAB_LINK}
         target="_blank"
         hidden={window.clientConfig.isWebview}
         onClick={this.handlePhotoLabClick}
         rel="noopener noreferrer">by Photo Lab</a>

      <a href="https://pho.to/privacy"
         target="_blank"
         hidden={window.clientConfig.isWebview}
         rel="noopener noreferrer">Privacy Policy</a>
    </div>;
  }
}