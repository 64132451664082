import DumbImageView from "./DumbImageView";
import React from "react";
import PropTypes from "prop-types";

export default function ProcessingResultView({processing, onImageLoaded}) {
  return <DumbImageView
    className="collage"
    image={processing.result.resultUrl}
    onImageLoaded={onImageLoaded}
  />;
}

ProcessingResultView.propTypes = {
  processing: PropTypes.object.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
}