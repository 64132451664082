import React from 'react';
import i18n from "../i18n";
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import Slider from "react-slick";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {assetUrl} from "../utils/etc";
import {FooterLinksView} from "../components/FooterLinksView";

export default class IndexPage extends React.Component {

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);
  }

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {page: "index"});
    hitEvent(hits.PHOTO_SELECT);

    this.props.history.push(routes.UPLOAD, {file});
  };

  render() {
    const sliderSettings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '65px',
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            centerPadding: '150px',
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            centerPadding: '60px',
          }
        },
        {
          breakpoint: 970,
          settings: {
            slidesToShow: 3,
            centerPadding: '0',
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            centerPadding: '200px',
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            centerPadding: '100px',
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: '80px',
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            centerPadding: '60px',
          }
        },
        {
          breakpoint: 380,
          settings: {
            slidesToShow: 1,
            centerPadding: '40px',
          }
        }
      ]
    };

    return <main className="main-page">
      <div className="container">
        <h1 className="main-title" dangerouslySetInnerHTML={{__html: i18n.t("landing__title")}} />
        <p className="main-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("landing__subtitle")}} />
        <img className="pumpkin-2 pumpkin-2-small" src={assetUrl(`assets/img/pumpkin-2.png`)} alt='' />
        <img className="pumpkin-2" src={assetUrl(`assets/img/pumpkin-2.png`)} alt='' />
      </div>

      <section className="wr-slider">
        <img className="pumpkin-1" src={assetUrl(`assets/img/pumpkin-1.png`)} alt='' />
        <img className="steam" src={assetUrl(`assets/img/steam.png`)} alt='' />
        <Slider {...sliderSettings}>
          {[1, 2, 3, 4, 5, 6].map((number) => <SlideView key={number} number={number} />)}
        </Slider>
      </section>

      <div className="container">
        {/* <p dangerouslySetInnerHTML={{__html: i18n.t("landing__text_1")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("landing__text_2")}} /> */}
        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("landing__select_photo_button")} />

        <FooterLinksView />
      </div>
    </main>;
  }
}

IndexPage.contextType = AppContext;

function SlideView({number}) {
  const jpg = [
    assetUrl(`assets/img/slider/main-${number}.jpg`) + " 1x",
    assetUrl(`assets/img/slider/main-${number}@2x.jpg`) + " 2x",
    assetUrl(`assets/img/slider/main-${number}@3x.jpg`) + " 3x",
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/slider/main-${number}.webp`) + " 1x",
    assetUrl(`assets/img/slider/main-${number}@2x.webp`) + " 2x",
    assetUrl(`assets/img/slider/main-${number}@3x.webp`) + " 3x",
  ].join(", ");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpg} alt="Demo" />
      </picture>
    </div>
  </div>;
}
