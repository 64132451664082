import Modal from "./Modal";
import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import {SvgSprite} from "./SvgSprite";

export default class DownloadModal extends Modal {

  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  renderModal() {
    return <div className="modal-container">
      <div className="modal">
        <svg viewBox="0 0 640 680" id="modal">
            <defs>
                <linearGradient x1="15.689%" y1="62.729%" x2="95.007%" y2="47.159%" id="c">
                    <stop stop-color="#FD5" offset="0%"/>
                    <stop stop-color="#FF543E" offset="22.102%"/>
                    <stop stop-color="#CA38A6" offset="49.89%"/>
                    <stop stop-color="#C837AB" offset="100%"/>
                </linearGradient>
                <linearGradient x1="6.714%" y1="93.377%" x2="93.608%" y2="6.727%" id="g">
                    <stop stop-color="#FD5" offset="0%"/>
                    <stop stop-color="#FF543E" offset="50%"/>
                    <stop stop-color="#C837AB" offset="100%"/>
                </linearGradient>
                <ellipse id="d" cx="25.131" cy="25.095" rx="25.131" ry="25.095"/>
                <ellipse id="f" cx="33.508" cy="33.46" rx="33.508" ry="33.46"/>
                <rect id="a" x="0" y="0" width="640" height="680" rx="80"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a"/>
                </mask>
                <g mask="url(#b)" fill="#FFEB3B" fill-rule="nonzero">
                    <path d="M553.263 65.375c-14.378 3.867-10.86-3.018-9.472-13.402-5.168 7.6-12.31 3.095-10.555-3.7-2.817 5.274-8.488 10.84-14.552 6.814-6.349-4.09-3.917-43.25-42.314-58.429 5.839 3.77 16.338 16.971 1.173 30.865 18.598.685 11.534 18.446 6.841 19.678 11.546 1.322 17.26 7.523 13.794 22.49 9.541-5.306 26.538-4.454 20.934 18.927 4.485-11.006 25.798-13.87 22.966 3.444 7.757-9.773 24.815-7.356 28.665 3.298-.196-9.886 12.795-18.095 21.134-11.78-.586-10.523 12.272-19.927 24.11-19.03-20.259-16.36-46.904-3.425-62.724.825z"/>
                </g>
                <g mask="url(#b)" fill="#00CE3F" fill-rule="nonzero">
                    <path d="M116.805 283.63c-11.397 9.582-11.118 1.855-14.249-8.143-1.471 9.073-9.848 8.008-11.13 1.108-.324 5.97-3.111 13.41-10.308 12.325-7.483-1.024-21.829-37.542-63.043-35.072 6.885.95 21.979 8.477 14.107 27.477 17.145-7.239 18.248 11.844 14.517 14.944 11.023-3.682 18.82-.477 22.006 14.553 6.405-8.84 22.17-15.252 26.97 8.306-.585-11.87 17.521-23.472 22.27-6.583 2.9-12.137 19.382-17.155 27.374-9.126-4.355-8.877 3.949-21.807 14.175-19.609-4.977-9.289 2.701-23.245 13.809-27.435-25.275-6.267-43.956 16.718-56.498 27.255z"/>
                </g>
                <g mask="url(#b)" fill="url(#c)" fill-rule="nonzero">
                    <path d="M334.305 99.515c-15.956 13.414-15.565 2.596-19.948-11.4-2.06 12.7-13.787 11.21-15.582 1.55-.454 8.359-4.356 18.775-14.432 17.255-10.476-1.433-30.56-52.558-88.26-49.1 9.64 1.33 30.77 11.867 19.75 38.468 24.003-10.135 25.548 16.58 20.323 20.92 15.433-5.153 26.35-.666 30.809 20.375 8.967-12.377 31.037-21.353 37.76 11.63-.821-16.62 24.528-32.862 31.177-9.218 4.06-16.991 27.135-24.016 38.323-12.776-6.098-12.428 5.529-30.53 19.845-27.452-6.969-13.005 3.781-32.544 19.333-38.41-35.385-8.773-61.54 23.406-79.098 38.158z"/>
                </g>
                <g mask="url(#b)" fill="#039BE5" fill-rule="nonzero">
                    <path d="M632.607 255.7c-20.13 5.414-15.204-4.225-13.26-18.763-7.236 10.641-17.234 4.334-14.778-5.18-3.944 7.384-11.883 15.176-20.373 9.54-8.888-5.727-5.484-60.55-59.24-81.8 8.175 5.278 22.873 23.759 1.643 43.21 26.037.959 16.147 25.825 9.577 27.55 16.165 1.85 24.163 10.531 19.312 31.485 13.358-7.427 37.153-6.235 29.307 26.498 6.28-15.409 36.118-19.416 32.152 4.823 10.86-13.684 34.742-10.299 40.131 4.617-.274-13.84 17.914-25.334 29.588-16.494-.82-14.73 17.18-27.896 33.754-26.64-28.362-22.906-65.665-4.795-87.813 1.154z"/>
                </g>
                <g mask="url(#b)" fill="#1877F2" fill-rule="nonzero">
                    <path d="M111.092 57.333c-5.376 20.14-11.261 11.054-22.88 2.103 5.599 11.586-4.863 17.091-11.873 10.207 4.422 7.108 7.2 17.879-1.926 22.413-9.403 4.834-55.179-25.525-100.46 10.403 8.658-4.44 32.012-7.928 38.242 20.183 13.849-22.07 30.438-1.071 28.647 5.48 9.686-13.073 21.202-15.66 36.923-.98.247-15.283 13.177-35.294 37.602-12.133-10.205-13.143 1.244-40.987 20.252-25.433-6.42-16.246 8.452-35.236 24.064-32.446-12.123-6.683-12.982-28.18.51-33.87-13.167-6.656-15.568-28.828-6.194-42.552-34.018 13.109-36.985 54.47-42.907 76.625z"/>
                </g>
                <g mask="url(#b)">
                    <g transform="rotate(20 162.584 1470.414)">
                        <mask id="e" fill="#fff">
                            <use xlinkHref="#d"/>
                        </mask>
                        <use fill="#FFEB3B" xlinkHref="#d"/>
                        <g fill-rule="nonzero" mask="url(#e)">
                            <path d="M35.603 34.247c1.228-.196 3.792-.66 3.863-1.896a.678.678 0 0 0-.563-.711v-.002c-3.79-.635-6.175-4.99-5.807-5.861.266-.63 1.45-.874 1.972-1.08 2.498-.999 1.421-2.71-.054-2.71-.665 0-1.453.72-2.18.381.225-3.83.625-6.529-2.088-9.139-3.523-3.382-10.67-2.988-13.108 2.569-.724 1.648-.447 4.662-.33 6.57-.513.229-1.247-.017-1.754-.26-1.278-.607-3.463 1.389-.477 2.589 1.295.516 2.519.663 1.835 2.076-.074.135-.49 1.165-1.464 2.317-2.811 3.326-5.372 1.966-4.699 3.56v.002c.427 1.014 2.695 1.42 3.791 1.595.302.573.083 1.89 1.11 1.89.553 0 1.604-.455 3.251-.174 2.22.377 4.743 5.067 10.324 1.065l.001-.003c1.203-.862 2.048-1.5 4.606-.988 1.855.365 1.34-.937 1.771-1.79z" fill="#FFF"/>
                            <path d="M25.004 39.221c.022.004-.041.008-.106.008-1.983 0-3.29-.94-4.342-1.696-3.841-2.763-5.57 1.43-6.496-2.733-5.31-.94-4.267-3.555-2.917-3.78 1.556-.262 2.827-1.148 3.83-2.333.656-.776 1.618-2.393 1.5-2.67-.125-.3-1.26-.592-1.626-.74-3.776-1.519-1.089-4.71.975-3.734.362.174.632.248.826.277-.115-1.877-.313-4.611.418-6.274 2.965-6.766 13.035-6.807 16.014.002.733 1.667.532 4.395.417 6.245.612-.152 1.295-.712 2.384-.262 1.702.618 2.125 2.913-1.044 3.907-.288.094-1.061.342-1.161.581-.147.35 1.81 4.415 5.333 5.004a1.301 1.301 0 0 1 1.084 1.365c-.09 1.588-2.42 2.131-4.003 2.412-.231 1.047-.403 1.944-1.56 1.944-1.977-.116-2.604-.885-4.936.792-1.38.985-2.736 1.801-4.59 1.685zm3.736-2.614c2.833-2.034 3.523-1.278 5.788-1.114.272 0 .078-.466.661-1.812.496-.1 3.298-.493 3.629-1.271h-.002l-.017-.153c-4.216-.707-6.052-5.027-6.128-5.21-1.25-2.57 2.199-2.55 3.093-3.48.362-.38.102-.713-.338-.873-.749-.31-1 .333-2.167.387-.597-.027-.735-.19-1.076-.327.277-3.895.66-6.639-1.872-9.075-3.196-3.07-9.834-2.8-12.098 2.369-.682 1.55-.377 4.687-.252 6.715-.241.046-.918.754-2.677-.092-.482-.23-2.168.56.027 1.446.591.236 1.928.488 2.316 1.418.596 1.433-2.302 6.05-6.28 6.718v.002l-.02.155c.352.84 3.496 1.23 3.621 1.268.581 1.396.206.593.531 1.712.133.45 1.243-.44 3.53-.043 2.39.406 4.729 4.838 9.731 1.26z" stroke="#222121" stroke-width="1.5" fill="#212121"/>
                        </g>
                    </g>
                </g>
                <g mask="url(#b)">
                    <g transform="translate(268.621 83.65)">
                        <mask id="h" fill="#fff">
                            <use xlinkHref="#f"/>
                        </mask>
                        <use fill="#D8D8D8" xlinkHref="#f"/>
                        <path d="M4.468 4.7C-.768 10.13.304 15.9.304 33.424c0 14.553-2.543 29.143 10.765 32.577 4.156 1.068 40.978 1.068 45.128-.005 5.54-1.428 10.049-5.916 10.665-13.741.086-1.092.086-36.55-.002-37.664C66.204 6.255 61.066 1.45 54.295.478c-1.552-.224-1.863-.29-9.824-.305C16.23.187 10.039-1.069 4.468 4.7z" fill="url(#g)" fill-rule="nonzero" mask="url(#h)"/>
                    </g>
                    <path d="M302.13 97.052c-8.235 0-16.055-.73-19.042 6.918-1.234 3.159-1.054 7.262-1.054 13.136 0 5.155-.166 10 1.054 13.134 2.98 7.653 10.863 6.92 19.037 6.92 7.886 0 16.016.82 19.04-6.92 1.235-3.19 1.054-7.232 1.054-13.134 0-7.834.433-12.892-3.375-16.689-3.855-3.847-9.07-3.365-16.723-3.365h.009zm-1.801 3.614c17.177-.027 19.364-1.932 18.157 24.537-.429 9.362-7.573 8.334-16.354 8.334-16.012 0-16.472-.457-16.472-16.44 0-16.168 1.27-16.422 14.669-16.435v.004zm12.528 3.329a2.408 2.408 0 0 0-2.41 2.405 2.408 2.408 0 0 0 2.41 2.406 2.408 2.408 0 0 0 2.41-2.406 2.408 2.408 0 0 0-2.41-2.405zm-10.727 2.813c-5.7 0-10.32 4.611-10.32 10.298s4.62 10.296 10.32 10.296c5.699 0 10.316-4.61 10.316-10.296 0-5.687-4.617-10.298-10.316-10.298zm0 3.614c8.856 0 8.867 13.369 0 13.369-8.854 0-8.868-13.37 0-13.37z" fill="#FFF" fill-rule="nonzero"/>
                </g>
                <g mask="url(#b)" fill-rule="nonzero">
                    <g transform="rotate(-30 171.965 -54.537)">
                        <ellipse fill="#1877F2" cx="33.269" cy="33.222" rx="33.269" ry="33.222"/>
                        <path d="M41.633 34.522h-5.936V56.24h-8.995V34.522h-4.277V26.89h4.277v-4.94c0-3.531 1.68-9.062 9.075-9.062l6.662.028v7.409h-4.834c-.793 0-1.908.395-1.908 2.08v4.492h6.722l-.786 7.625z" fill="#FFF"/>
                    </g>
                </g>
                <g mask="url(#b)">
                    <g transform="translate(68.691 268.792)">
                        <ellipse fill="#00CE3F" cx="25.131" cy="25.095" rx="25.131" ry="25.095"/>
                        <path d="M35.008 15.22a13.816 13.816 0 0 0-9.828-4.067c-7.658 0-13.891 6.222-13.894 13.869a13.826 13.826 0 0 0 1.854 6.934l-1.97 7.187 7.365-1.929a13.904 13.904 0 0 0 6.64 1.688h.005c7.657 0 13.89-6.222 13.894-13.869a13.773 13.773 0 0 0-4.066-9.812" fill="#FFF"/>
                        <path d="M25.18 36.56h-.005c-2.072 0-4.104-.557-5.877-1.607l-.422-.25-4.37 1.145 1.166-4.254-.275-.436a11.486 11.486 0 0 1-1.765-6.135c.002-6.356 5.183-11.527 11.553-11.527a11.48 11.48 0 0 1 8.164 3.38 11.448 11.448 0 0 1 3.38 8.156c-.004 6.357-5.184 11.528-11.549 11.528" fill="#00CE3F"/>
                        <path d="M31.514 27.926c-.347-.173-2.054-1.012-2.372-1.127-.318-.116-.55-.174-.78.173-.232.347-.898 1.128-1.1 1.359-.203.231-.405.26-.752.087-.348-.174-1.466-.54-2.792-1.72-1.032-.92-1.729-2.054-1.931-2.401-.203-.347-.022-.535.152-.707.156-.156.347-.405.52-.607.174-.203.232-.347.348-.579.115-.23.058-.433-.03-.607-.086-.173-.78-1.879-1.07-2.573-.281-.676-.568-.584-.78-.595-.203-.01-.435-.012-.666-.012-.232 0-.608.087-.926.434-.318.347-1.215 1.185-1.215 2.89 0 1.706 1.244 3.354 1.418 3.586.173.231 2.448 3.731 5.93 5.233.828.357 1.475.57 1.98.73.83.263 1.588.226 2.186.137.667-.1 2.054-.839 2.343-1.648.29-.81.29-1.503.203-1.648-.087-.145-.318-.231-.666-.405" fill="#FFF"/>
                    </g>
                </g>
                <g mask="url(#b)" fill-rule="nonzero">
                    <g transform="translate(562.932 225.853)">
                        <ellipse fill="#039BE5" cx="33.508" cy="33.46" rx="33.508" ry="33.46"/>
                        <path d="M15.333 32.735 47.64 20.296c1.5-.54 2.809.365 2.323 2.63l.003-.003L44.465 48.8c-.408 1.835-1.5 2.28-3.027 1.416l-8.377-6.165-4.04 3.887c-.447.446-.824.823-1.69.823l.595-8.513 15.525-14.005c.676-.594-.15-.929-1.041-.338L23.224 37.968l-8.271-2.576c-1.796-.569-1.835-1.793.38-2.657z" fill="#FFF"/>
                    </g>
                </g>
            </g>
        </svg>
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("modal_title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("modal_message")}} />

        <button className="btn-close" children={i18n.t("modal_button")} onClick={() => this.dismiss()} />
      </div>
    </div>
  }
}

DownloadModal.contextType = AppContext;
