import React from "react";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {photolabTask} from "../../photolab/api";
import ProcessingResultView from "../../components/ProcessingResultView";
import ProcessingErrorView from "../../components/ProcessingErrorView";
import TemplateItemView from "../../components/TemplateItemView";
import {assetUrl, debounce} from "../../utils/etc";
import Loader from "../../components/Loader";
import i18n from "../../i18n";
import * as api from "../../utils/api";
import {logEvent, userEvents} from "../../utils/log";
import FileChooseButton from "../../components/FileChooseButton";

const templatesList = [
  {id: 5873, name: "Frankenstein"},
  {id: 5874, name: "Jack Skellington"},
  {id: 5875, name: "Goblin"},
  {id: 5876, name: "Corpse Bride"},
  // {id: 5877, name: "Calavera Makeup"},
  {id: 5878, name: "Zombie v2"},
  // {id: 5879, name: "Corpse Bride v2"},
  // {id: 5880, name: "The Addams Family"},
  {id: 5881, name: "Zombie"},
  // {id: 5882, name: "Evil Santa"},
  {id: 5883, name: "Scary Nurse"},
  {id: 5884, name: "Joker"},
  {id: 5885, name: "Harley Quinn"},
  {id: 5886, name: "Evil Doll"},
  // {id: 5887, name: "Creepy Doll"},
  // {id: 5888, name: "Michael Myers"},
  // {id: 5889, name: "Hotel Transylvania"},
  // {id: 5890, name: "Drunk Clown"},
  {id: 5891, name: "Scary Clown v2"},
  {id: 5892, name: "Scary Clown"},
  {id: 5893, name: "Demon"},
  {id: 5894, name: "Killer Robot"},
  {id: 5895, name: "Creepy Banshee"},
  // {id: 5896, name: "Evil Mummy"},
  {id: 5897, name: "Chucky"},
  {id: 5898, name: "Chucky v2"},
  // {id: 5899, name: "Halloween Ghoul"},
  {id: 5900, name: "Gremlin"},
  // {id: 5901, name: "Troll"},
  // {id: 5902, name: "Lord Voldemort"},
  // {id: 5903, name: "Creepy Mummy"},
  {id: 5907, name: "Gremlin v2"},
  {id: 5908, name: "Beetlejuice"},
  {id: 5909, name: "Orc"},
  {id: 5910, name: "Skull Face"},
  // {id: 5911, name: "Mysterious Sorceress"},
  {id: 5912, name: "Judge Doom"},
  {id: 5913, name: "Monsters"},
  {id: 5914, name: "Lord Voldemort v2"},
  // {id: 5915, name: "Devil Face"},
  {id: 5916, name: "Boogeyman"},
  {id: 5917, name: "Dracula"},
  // {id: 5918, name: "Annabelle Doll"},
  // {id: 5919, name: "Annabelle Doll v2"},
  // {id: 5920, name: "Calavera Makeup v2"},
  {id: 5921, name: "Creepy Banshee v2"},
  // {id: 5925, name: "Haunted"},
  {id: 5926, name: "Vampire"},
  {id: 5927, name: "Werewolf"},
  {id: 5928, name: "Evil Goblin"},
].shuffle();

function getTemplatePreviewUrl(template) {
  return assetUrl(`assets/images/halloween/templates/${template.previewFileName || template.id}.jpeg`);
}

export default class SpooooookyTab extends React.Component {

  state = {
    isLoading: false,
    template: templatesList[0],
    processings: [],
  };

  constructor(props) {
    super(props);
    this.headTaskPromise = null;
    this.cropTaskPromise = null;
    this.templateIsUserSelect = false;
    this.templatesListRef = React.createRef();
  }

  componentDidMount() {
    this.setTemplatesListWheelEvent();
    this.handleTemplateSelect(templatesList[0]);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setTemplatesListWheelEvent();

    if (this.props.file !== prevProps.file) {
      this.headTaskPromise = null;
      this.cropTaskPromise = null;

      this.setState({
        processings: [],
      }, () => this.handleTemplateSelect(this.state.template));
    }
  }

  setTemplatesListWheelEvent = () => {
    if (this.templatesListRef.current) {
      this.templatesListRef.current.removeEventListener("wheel", this.handleTemplatesListMouseWheel);
      this.templatesListRef.current.addEventListener("wheel", this.handleTemplatesListMouseWheel);
    }
  };

  handleTemplatesListMouseWheel = (e) => {
    if (this.templatesListRef.current) {
      this.templatesListRef.current.scrollLeft += e.deltaY;
    }
  };

  handleTemplateSelect = (template, isUserSelect = false) => {
    this.templateIsUserSelect = !!isUserSelect;

    logEvent(userEvents.TEMPLATE_SELECT, {
      tab: this.props.tabName,
      template_id: template.id,
      template_is_user_select: this.templateIsUserSelect,
    });

    this.setState({template}, this.startTemplate);
  };

  startTemplate = () => {
    const processing = this.state.processings.find((p) => p.template.id === this.state.template.id)
      || {};

    if (processing.template) {
      return;
    }

    processing.tries = 0;
    processing.template = this.state.template;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;

    this.state.processings.push(processing);

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  };

  startProcessing = (processing) => {
    this.getCropTask()
      .then(() => {
        return Promise.all([
          this.getHeadTask(),
          photolabTask(new PhotolabTaskBuilder()
            .addImage(new PhotolabTaskImageUrl(this.props.file))
            .addMethod(new PhotolabTaskCollageMethod(processing.template.id))
            .setLanguage(window.clientConfig.lang || "en")
            .build())
        ])
      })
      .then(([headTaskResult, templateTaskResult]) => {
        return Promise.all([
          headTaskResult,
          photolabTask(new PhotolabTaskBuilder()
            .addMethod(new PhotolabTaskCollageMethod(6037))
            .addImage(new PhotolabTaskImageUrl(templateTaskResult.resultUrl))
            .build())
        ]);
      })
      .then(([headTaskResult, templateTaskResult]) => {
        return photolabTask(new PhotolabTaskBuilder()
          .addMethod(new PhotolabTaskCollageMethod([6056, 6057, 6058].random()))
          .addImage(new PhotolabTaskImageUrl(templateTaskResult.resultUrl))
          .addImage(new PhotolabTaskImageUrl(headTaskResult.resultUrl))
          .build())
      })
      .then((taskResult) => api.createTask("creative_store", {
        content_url: taskResult.resultUrl,
        watermark: "creepyartist",
      }))
      .then((taskResult) => api.waitTask(taskResult.id))
      .then((taskResult) => {
        processing.isProcessing = false;
        processing.isProcessed = true;
        processing.result = {resultUrl: taskResult.result.url};
      })
      .catch((err) => {
        processing.isProcessing = false;
        processing.isFailed = true;
        processing.error = err;
      })
      .then(() => {
        this.setState({processings: this.state.processings.slice()});
      });
  };

  getCropTask = () => {
    if (this.cropTaskPromise != null) {
      return this.cropTaskPromise;
    }

    const headConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(4021))
      .addImage(new PhotolabTaskImageUrl(this.props.file))
      .build();

    return this.cropTaskPromise = photolabTask(headConfig);
  }

  getHeadTask = () => {
    if (this.headTaskPromise != null) {
      return this.headTaskPromise;
    }

    const taskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(5779))
      .addImage(new PhotolabTaskImageUrl(this.props.file))
      .build();

    return this.headTaskPromise = photolabTask(taskConfig, 3000);
  };

  handleRetryClick = (processing) => debounce("SpooooookyTab.handleRetryClick", 200, () => {
    processing.tries++;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;

    this.setState({processings: this.state.processings.slice()}, () => {
      this.startProcessing(processing);
    });
  });

  handleResultLoaded = (processing) => {
    logEvent(userEvents.TEMPLATE_LOADED, {
      tab: this.props.tabName,
      template_id: processing.template.id,
      template_is_user_select: this.templateIsUserSelect,
    });
  };

  handleDownloadStartClick = (processing) => debounce("SpooooookyTab.handleDownloadStartClick", 200, () => {
    logEvent(userEvents.TEMPLATE_DOWNLOAD, {
      tab: this.props.tabName,
      template_id: processing.template.id,
      template_is_user_select: this.templateIsUserSelect,
    });

    this.props.onDownloadFile(processing.result.resultUrl);
  });

  render() {
    if (this.state.isLoading) {
      return <Loader hidden={this.props.hidden} />;
    }

    const processing = this.state.processings
      .find((p) => p.template.id === this.state.template.id);

    return <div className="tab-content" hidden={this.props.hidden}>
      <div className="collage-container">
        <div className="container">
          <Loader
            hidden={processing && !processing.isProcessing}
            message={this.props.loaderText} />

          {processing && processing.isProcessed && <ProcessingResultView
            processing={processing}
            onImageLoaded={() => this.handleResultLoaded(processing)}
          />}

          {processing && processing.isFailed && <ProcessingErrorView
            processing={processing}
            onFileSelected={this.props.onFileSelected}
            onRetryClick={() => this.handleRetryClick(processing)}
          />}
        </div>
      </div>

      <div className="templates-container">
        <div className="container" ref={this.templatesListRef}>
          {templatesList.map((template) => <TemplateItemView
            key={template.id}
            template={template}
            className="named"
            isActive={this.state.template && this.state.template.id === template.id}
            previewUrl={getTemplatePreviewUrl(template)}
            children={<span dangerouslySetInnerHTML={{__html: template.name.replace(/\sv2$/, "&nbsp;v2")}} />}
            onClick={() => this.handleTemplateSelect(template, true)}
          />)}
        </div>
      </div>

      <div className={"footer-btns" + ((!processing || !processing.isProcessed) ? " invisible" : "")}>
        <FileChooseButton onFileSelected={this.props.onFileSelected} className="try-photo">
          <div className="try-photo-container">
            <img src={assetUrl(`assets/img/photo.png`)} alt="." />
          </div>
          {i18n.t("try_another_photo")}
        </FileChooseButton>
        <button
          className="btn-done"
          dangerouslySetInnerHTML={{__html: i18n.t("collage__download")}}
          onClick={() => this.handleDownloadStartClick(processing)}
        />
      </div>
    </div>
  }
}