import i18n from "../i18n";
import {PhotolabResponseError} from "../photolab/api";
import React from "react";
import PropTypes from "prop-types";
import FileChooseButton from "./FileChooseButton";

const photolabBadPhotoErrorCodes = [
  -6,
  -10,
  -1000,
  -1002,
  -1010,
  -1011,
  -1020,
  -1022,
  -1027,
  -1028,
  -1029,
  -1031,
];

export default function ProcessingErrorView({processing, onRetryClick, onFileSelected}) {
  let errorMessage = i18n.t("error__default_message");
  let button = <button
    dangerouslySetInnerHTML={{__html: i18n.t("btn_retry")}}
    onClick={() => onRetryClick(processing)}
  />;

  if (processing.error instanceof PhotolabResponseError) {
    errorMessage = processing.error.message;
    if (photolabBadPhotoErrorCodes.indexOf(processing.error.code) > -1) {
      button = <FileChooseButton onFileSelected={onFileSelected} children={i18n.t("try_another_photo")} />;
    }
  } else if (processing.error.name === "NetworkError" || processing.error.message === "Network Error") {
    errorMessage = i18n.t("error__network_message");
  }

  return <div className="error-container" hidden={!processing || !processing.isFailed}>
    <p dangerouslySetInnerHTML={{__html: errorMessage}} />
    {button}
  </div>;
}

ProcessingErrorView.propTypes = {
  processing: PropTypes.object.isRequired,
  onRetryClick: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
}